import "../styles/index.scss";
import dejan1Image from "../img/dejan1.jpg";
import dejan2Image from "../img/dejan2.jpg";
import dejan3Image from "../img/dejan3.jpg";
import dejan4Image from "../img/dejan4.jpg";
import dejan5Image from "../img/dejan5.jpg";
import daskeUserImage from "../img/daske-user.jpg";
import cigleImage from "../img/cigle-user.jpg";
import cementImage from "../img/cement-user.jpg";
import cementUser1Image from "../img/cement1-user.jpg";
import cementUser2Image from "../img/cement2-user.jpg";
import cementUser3Image from "../img/cement3-user.jpg";
import cementUser4Image from "../img/cement4-user.jpg";
import cementUser5Image from "../img/cement5-user.jpg";
import cementUser6Image from "../img/cement6-user.jpg";
import armaturaImage from "../img/armatura-user.jpg";
import brickImage from "../img/brick.jpg";
import woodImage from "../img/wood.jpg";
import cementIntroImage from "../img/cement.jpg";

(() => {
  // variables
  const introContainer = document.getElementById("intro-container");
  const picturesIntro = [brickImage, woodImage, cementIntroImage];

  const picturesGallery = [
    armaturaImage,
    cementUser1Image,
    cementUser2Image,
    cementUser3Image,
    cementUser4Image,
    cementUser5Image,
    cementUser6Image,
    cementImage,
    cigleImage,
    daskeUserImage,
    dejan1Image,
    dejan2Image,
    dejan3Image,
    dejan4Image,
    dejan5Image,
  ];
  const balls = document.getElementById("balls");
  const tape = document.getElementById("tape");
  const navbar = document.getElementById("navbar");
  const progressBar = document.getElementById("progress-bar");
  const productsSection = document.getElementById("product-section");
  const hamburgerMenu = document.getElementById("hamburger");
  const sidebarMenu = document.getElementById("sidebar-menu");
  const closeSidenavMenu = document.getElementById("close-sidenav");
  const navbarList = document.querySelectorAll(".navbar-list");
  const knowMoreBtn = document.getElementById("know-more");
  const productsGallery = document.getElementById("products-gallery");
  const galleryModal = document.getElementById("gallery-modal");
  const close = document.getElementById("close");
  const modalPicture = document.getElementById("modal-picture");
  const pictureCounter = document.getElementById("counter");
  const rightArrow = document.getElementById("right-arrow");
  const leftArrow = document.getElementById("left-arrow");
  const rights = document.getElementById("rights");
  const form = document.getElementById("form");
  const name = document.getElementById("name");
  const email = document.getElementById("email");
  const message = document.getElementById("message");
  const validation = document.getElementById("validation");
  const toaster = document.getElementById("toaster");
  const submitToMail = "siskoftn@gmail.com";
  let interval = null;
  let counter = 0;
  let gid;
  // set active class to span ball
  function setActiveBall() {
    Array.from(balls.children).forEach((item) => {
      if (Number.parseInt(item.dataset.id, 0) === counter) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    });
  }

  // set counter for slideshow
  function setCounter() {
    counter += 1;
    if (counter === picturesIntro.length) {
      counter = 0;
    }
    setActiveBall();
    introContainer.style.backgroundImage = `url('${picturesIntro[counter]}')`;
  }

  // set slideshow with pictures
  function setSlideShow() {
    introContainer.style.backgroundImage = `url('${picturesIntro[counter]}')`;
    picturesIntro.forEach((item, index) => {
      const span = document.createElement("span");
      span.dataset.id = index;
      balls.appendChild(span);
    });
    setActiveBall();
    interval = setInterval(setCounter, 5000);
  }

  // clicked on span balls from gallery
  function chooseBall(e) {
    if (e.target.matches("span")) {
      const id = Number.parseInt(e.target.dataset.id, 0);
      counter = id;
      balls.innerHTML = "";
      clearInterval(interval);
      setSlideShow();
    }
  }

  // reduce events to time in ms
  function debounce(func, wait, immediate) {
    let timeout = null;
    return function () {
      const args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(this, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(this, args);
    };
  }

  // on scrolling fire events
  function scrolledEvents() {
    if (window.scrollY < productsSection.offsetTop) {
      tape.style.top = `${18 + window.scrollY / 100}em`;
    }
    if (window.scrollY > navbar.offsetTop) {
      navbar.classList.add("fixed");
    } else {
      navbar.classList.remove("fixed");
    }
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    progressBar.style.width = `${Math.ceil((window.scrollY / height) * 100)}%`;
  }

  // toggle sidenav on hamburger menu click
  function toggleSidenav() {
    sidebarMenu.classList.toggle("toggle-menu");
  }

  // close sidenav on sidenav exit link
  function closeSidenav() {
    hamburgerMenu.checked = false;
    sidebarMenu.classList.toggle("toggle-menu");
  }

  // reset clicked link color
  function resetLinkStyle() {
    navbarList.forEach((list) => {
      Array.from(list.children).forEach((item) => {
        if (item.children[0]) {
          item.children[0].classList.remove("active-link");
        }
      });
    });
  }

  // set scrolling in motion
  function scrollDownLinks(e) {
    resetLinkStyle();
    const target = e.target.dataset.section;
    const element = document.getElementById(`${target}`);
    if (e.target.matches("a")) {
      e.preventDefault();
      e.target.classList.add("active-link");
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: element.offsetTop,
      });
    }
  }

  // set pictures in gallery
  function setGallery() {
    picturesGallery.forEach((picture, index) => {
      const div = document.createElement("div");
      div.innerHTML = `
      <img src="${picture}" alt="${picture}" data-gid="${index + 1}">
      `;
      productsGallery.appendChild(div);
    });
  }

  // show gallery modal
  function showGalleryModal(e) {
    if (e.target.matches("img")) {
      const imgSrc = e.target;
      gid = Number.parseInt(imgSrc.dataset.gid, 0);
      pictureCounter.innerHTML = `${gid}/${picturesGallery.length}`;
      modalPicture.src = imgSrc.src;
      galleryModal.classList.add("activeModal");
      document.body.style.overflowY = "hidden";
    }
  }

  // close gallery modal
  function closeModal() {
    galleryModal.classList.remove("activeModal");
    document.body.style.overflowY = "visible";
  }

  // showNextPicture on right arrow click
  function showNextPicture() {
    if (gid + 1 > picturesGallery.length) {
      pictureCounter.innerHTML = `${1}/${picturesGallery.length}`;
      const nextPicture = document.querySelector(`img[data-gid="${1}"]`);
      modalPicture.src = nextPicture.src;
      gid = 1;
    } else {
      pictureCounter.innerHTML = `${gid + 1}/${picturesGallery.length}`;
      const nextPicture = document.querySelector(`img[data-gid="${gid + 1}"]`);
      modalPicture.src = nextPicture.src;
      gid += 1;
    }
  }

  // showPreviousPicture
  function showPreviousPicture() {
    if (gid === 1) {
      pictureCounter.innerHTML = `${picturesGallery.length}/${picturesGallery.length}`;
      const nextPicture = document.querySelector(
        `img[data-gid="${picturesGallery.length}"]`
      );
      modalPicture.src = nextPicture.src;
      gid = picturesGallery.length;
    } else {
      pictureCounter.innerHTML = `${gid - 1}/${picturesGallery.length}`;
      const nextPicture = document.querySelector(`img[data-gid="${gid - 1}"]`);
      modalPicture.src = nextPicture.src;
      gid -= 1;
    }
  }

  // set footer rights description with year
  function setRightsAndForm() {
    rights.innerHTML = `
    Copyright ${new Date().getFullYear()}. &copy; GM Ivanković -
    Sva prava zadržana!
    `;
    form.action = `https://formspree.io/${submitToMail}`;
    form.method = "POST";
    if (localStorage.getItem("emailSubmited") === "true") {
      toaster.style.display = "block";
      setTimeout(() => {
        toaster.style.display = "none";
      }, 4000);
    }
    localStorage.setItem("emailSubmited", "false");
  }

  // reset validation field
  function resetValidation(time) {
    setTimeout(() => {
      validation.textContent = "";
    }, time);
  }

  // form submit event
  function formSubmit(e) {
    const whitespace = /^\s+$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (name.value.trim() === "") {
      e.preventDefault();
      validation.textContent = "Molimo Vas unesite ime!";
      resetValidation(3000);
      return;
    }
    if (!emailRegex.test(email.value)) {
      validation.textContent = "Molimo Vas unesite email ispravnog formata!";
      e.preventDefault();
      resetValidation(3000);
      return;
    }
    if (whitespace.test(message.value) || message.value.trim() === "") {
      e.preventDefault();
      validation.textContent = "Molimo Vas unesite određenu poruku!";
      resetValidation(3000);
      return;
    }
    const confirm = window.confirm(
      "Da li ste sigurni da želite da pošaljete poruku?"
    );
    if (confirm) {
      localStorage.setItem("emailSubmited", "true");
    }
  }
  // debounced function - reduced events
  const scrollDebounced = debounce(scrolledEvents, 50);

  // event Listeners
  document.addEventListener("DOMContentLoaded", () => {
    setSlideShow();
    setGallery();
    setRightsAndForm();
  });
  document.addEventListener("scroll", scrollDebounced);
  balls.addEventListener("click", chooseBall);
  hamburgerMenu.addEventListener("click", toggleSidenav);
  closeSidenavMenu.addEventListener("click", closeSidenav);
  knowMoreBtn.addEventListener("click", scrollDownLinks);
  navbarList.forEach((list) => list.addEventListener("click", scrollDownLinks));
  productsGallery.addEventListener("click", showGalleryModal);
  close.addEventListener("click", closeModal);
  rightArrow.addEventListener("click", showNextPicture);
  leftArrow.addEventListener("click", showPreviousPicture);
  form.addEventListener("submit", formSubmit);
})();
